import { IService } from '@/modules/interfaces';
import { responseProcessing } from '@/utils/processing/responseProcessing';
import { HTTP_METHOD } from '@/utils/processing/interface';

export interface SendHttpRequestParams {
  body: object;
  method: HTTP_METHOD;
  url: string;
}

export class SendHttpRequest implements IService<SendHttpRequestParams, void> {
  async call(params: SendHttpRequestParams): Promise<void> {
    const { body, method, url } = params;

    return await responseProcessing<never>({
      data: JSON.stringify(body),
      method,
      auth: {
        username: 'ck_b36c22cbf7b6b266e022538b94c7d6d8ada16013',
        password: 'cs_51512024dfc4026bdb52cdf6472f4179aed000ae',
      },
      url,
    });
  }
}
