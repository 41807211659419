import axios, { AxiosRequestConfig } from 'axios';
import { throwUnknownError } from '../errors/errors';

export async function responseProcessing<T>(
  params: AxiosRequestConfig
): Promise<T> {
  try {
    const response = await axios.request<T>(params);
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err; // TODO - throw specific exception
    } else {
      throwUnknownError({
        context: params,
      });
    }
  }
}
