export enum ErrorType {
  NETWORK_ERROR = 'network_error',
  TIMEOUT_ERROR = 'timeout_error',
  FORBIDDEN = 'forbidden',
  INVALID_REQUEST = 'invalid_request',
  AUTH_ERROR = 'authentication_error',
  NOT_FOUND = 'not_found',
  UNKNOWN_ERROR = 'unknown_error',
}

export interface CustomErrorParams {
  message?: string;
  context: unknown;
}
