import { CustomErrorParams, ErrorType } from './interface';

export class CustomError extends Error {
  public readonly type: ErrorType;
  public readonly context: unknown;
  constructor(type: ErrorType, params?: CustomErrorParams) {
    super();
    this.type = type;
    this.context = params?.context;
    this.message = params?.message || this.getMessage();
  }

  private getMessage(): string {
    switch (this.type) {
      case ErrorType.NETWORK_ERROR:
        return 'Nastala chyba sítě';
      case ErrorType.TIMEOUT_ERROR:
        return 'Vypršel časový limit požadavku';
      case ErrorType.FORBIDDEN:
        return 'Požadavek byl zamítnul';
      case ErrorType.INVALID_REQUEST:
        return 'Nevalidní požadavek';
      case ErrorType.AUTH_ERROR:
        return 'Chyba autorizace';
      default:
        return 'Neznámá chyba';
    }
  }
}

export function throwForbiddenError(params?: CustomErrorParams) {
  throw new CustomError(ErrorType.FORBIDDEN, params);
}

export function throwTimeoutError(params?: CustomErrorParams) {
  throw new CustomError(ErrorType.TIMEOUT_ERROR, params);
}

export function throwNetworkError(params?: CustomErrorParams) {
  throw new CustomError(ErrorType.NETWORK_ERROR, params);
}

export function throwInvalidError(params?: CustomErrorParams) {
  throw new CustomError(ErrorType.INVALID_REQUEST, params);
}

export function throwUnknownError(params?: CustomErrorParams): never {
  throw new CustomError(ErrorType.UNKNOWN_ERROR, params);
}

export function throwNotFoundError(params?: CustomErrorParams): never {
  throw new CustomError(ErrorType.NOT_FOUND, params);
}
