import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_qrcode_stream = _resolveComponent("qrcode-stream")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.scanStore.loading)
      ? (_openBlock(), _createBlock(_component_v_card_actions, {
          key: 0,
          class: "justify-center align-content-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, null, {
              default: _withCtx(() => [
                _createTextVNode(" Loading ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_progress_circular, {
              size: 70,
              width: 7,
              color: "blue",
              "justify-center": "",
              indeterminate: ""
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!$setup.scanStore.loading)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      label: "URL",
                      modelValue: $setup.scanStore.url,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.scanStore.url) = $event)),
                      required: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_qrcode_stream, {
                  onDetect: $setup.scanStore.onDetect,
                  onOnInit: $setup.scanStore.onInit,
                  onDecode: $setup.scanStore.onDecode,
                  onError: $setup.scanStore.onError,
                  onTorch: $setup.scanStore.torch,
                  onCamera: $setup.scanStore.camera
                }, null, 8, ["onDetect", "onOnInit", "onDecode", "onError", "onTorch", "onCamera"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($setup.scanStore.result.length > 0)
      ? (_openBlock(), _createBlock(_component_v_dialog, {
          key: 2,
          modelValue: $setup.scanStore.dialog,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.scanStore.dialog) = $event)),
          activator: "parent",
          width: "auto",
          persistent: "",
          scrollable: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_text, {
                  textContent: _toDisplayString($setup.scanStore.result)
                }, null, 8, ["textContent"]),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "primary",
                      block: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.scanStore.result = ''))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Close Dialog")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}