import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import VueQrcodeReader from 'vue-qrcode-reader';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
});

// Pinia
import { createPinia } from 'pinia';
import communicationModule from './modules/communicationModule/CommunicationModule';

// Create application
const pinia = createPinia();
const app = createApp(App);

// Register app & component
app.use(VueQrcodeReader).use(pinia).use(router).use(vuetify).mount('#app');

// Register modules
communicationModule.register(pinia);
