import { IModule, IService } from '../interfaces';
import { InjectionKey } from 'vue';
import SendWsRequest from './services/SendWsRequest';
import { SendHttpRequest, SendHttpRequestParams } from './services';
import { Pinia } from 'pinia';

export const SendHttpSymbol: InjectionKey<
  IService<SendHttpRequestParams, void>
> = Symbol('SendHttpSymbol');

class CommunicationModule implements IModule {
  register(pinia: Pinia): void {
    // Create services
    const sendHttpRequest = new SendHttpRequest();
    const sendWsRequest = new SendWsRequest();

    // Register services
    pinia.use(({ store }) => {
      if (store.$id === 'scanStore') {
        store.sendHttpRequest = sendHttpRequest;
        store.sendWsRequest = sendWsRequest;
      }
    });
  }
}

const communicationModule = new CommunicationModule();
export default communicationModule;
