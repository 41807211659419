import { HTTP_METHOD } from '@/utils/processing/interface';
import { defineStore } from 'pinia';

export enum CAMERA_STATE {
  AUTO = 'auto',
  REAR = 'rear',
  FRONT = 'front',
  OFF = 'off',
}

interface ScanStoreStates {
  url: string;
  torch: boolean;
  torchSupported: boolean;
  camera: CAMERA_STATE;
  result: string;
  loading: boolean;
}

interface ScanStoreGetters {
  isScanning(): boolean;
  [key: string]: any;
}

interface ScanStoreActions {
  changeCamera(): Promise<void>;
  onInit(promise: Promise<object>): Promise<void>;
  onDetect(promise: Promise<any>): Promise<void>;
  onError(error: Error): Promise<void>;
  onCameraOn(): Promise<void>;
  onCameraOff(): Promise<void>;
}

export const useScanStore = defineStore<
  'scanStore',
  ScanStoreStates,
  ScanStoreGetters,
  ScanStoreActions
>('scanStore', {
  state: () => {
    return {
      url: '',
      torch: false,
      torchSupported: false,
      camera: CAMERA_STATE.AUTO,

      result: '',
      loading: false,
    };
  },
  getters: {
    isScanning() {
      return true;
    },
  },
  actions: {
    async onCameraOn() {
      console.log('onCameraOn');
    },
    async onCameraOff() {
      console.log('onCameraOff');
    },
    async changeCamera() {
      this.camera = CAMERA_STATE.FRONT;
      /*if (this.camera !== param) {
        alert('yes');
      }*/
    },
    async onInit(promise: Promise<object>) {
      const info = await promise;
      console.log(info);
    },
    async onDetect(promise: Promise<any>) {
      if (this.loading) return;
      if (!this.url.length) {
        return alert('There is no URL');
      }
      console.log('onDetect callback');
      try {
        this.loading = true;
        const { content } = await promise;
        if (!content || !content.lenght) {
          return alert('No data from scanner');
        }

        const result = await this.sendHttpRequest.call({
          /* body: {
            params: content,
          }, */
          method: HTTP_METHOD.GET,
          url: this.url + content,
        });
        this.result = JSON.stringify(result);
      } catch (err: any) {
        this.result = '';
        alert('onDetect error: ' + err?.message);
      } finally {
        this.loading = false;
      }
    },
    async onError(error: Error): Promise<void> {
      console.error(error);
      if (error.name === 'NotAllowedError') {
        // user denied camera access permisson
      } else if (error.name === 'NotFoundError') {
        // no suitable camera device installed
      } else if (error.name === 'NotSupportedError') {
        // page is not served over HTTPS (or localhost)
      } else if (error.name === 'NotReadableError') {
        // maybe camera is already in use
      } else if (error.name === 'OverconstrainedError') {
        // did you requested the front camera although there is none?
      } else if (error.name === 'StreamApiNotSupportedError') {
        // browser seems to be lacking features
      } else if (error.name === 'DropImageFetchError') {
        // drag-and-dropped URL (probably just an <img> element) from different
        // domain without CORS header caused same-origin-policy violation
      } else if (error.name === 'DropImageDecodeError') {
        // drag-and-dropped file is not of type image and can't be decoded
      } else {
        // idk, open an issue ¯\_(ツ)_/¯
      }
    },
  },
});
