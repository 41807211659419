
import { useScanStore } from '@/store/scanStore';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: { QrcodeStream },
  setup() {
    const scanStore = useScanStore();
    return { scanStore };
  },
};
